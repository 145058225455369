:root {
  --primary: #1E1E1E;
  --secondary: #D59A2C;
}
@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@400;700;800&family=Amiri&family=IBM+Plex+Sans+Arabic:wght@200;600;700&display=swap');

body{
  box-sizing: border-box;
  direction: rtl;
  line-height: 1.5;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.3s ease;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  font-family: 'Almarai', sans-serif;
}
*{
  background-size: cover;
  background-repeat: no-repeat;
}
html {
  scroll-behavior: smooth;
}
a{
  text-decoration: none;
  color: inherit;
}
ul {
  list-style: none !important;
  padding: 0;
  margin: 0;
}
h1,.h1{
  font-size: 44px;
  font-family: 'Amiri', serif;
  margin-bottom: 20px;
  font-weight: 700;
}
h2,.h2{
  font-size: 40px;
}
h3,.h3{
  font-size: 24px;
}
h4,.h4{
  font-size: 20px;
}
label{
  margin-bottom: 7px;
}
button:disabled {
  opacity: 0.5;
}
input:focus-visible,
textarea:focus-visible {
  outline: none;
}

textarea::-webkit-resizer,
input::-webkit-inner-spin-button {
  display: none;
}
*::-webkit-scrollbar {
  display: none;
}
/* global class */
.font_primary{
  font-family: "Amiri";
}
.text_white{
  color: white;
}
.text_black{
  color:black
}
.text_greay{
  color: gray;
}
.text_secondary{
  color: var(--secondary);
}
.text_sucsess{
  color: #4ACA5B;
}
.text_active{
  color: var(--secondary);
}
.button_ative{
  border: solid 2px var(--secondary) !important;
  color: var(--secondary);
}
.button_hover:hover{
  border: solid 2px var(--secondary) !important;
  color: var(--secondary);
}
.text_english{
  direction: ltr;
}
.text_hover_active:hover{
  color: var(--secondary) !important;
}
.bg_primary{
  background-color: #1E1E1E;
  color: white;
}
.bg_secondary{
  background-color: var(--secondary);
  color: white;
}
.bg_red{
  background-color: #F61C0D;
  color: white;
}
.bg_success{
  background-color: #4ACA5B;
  color: white;
}
.bg_greay{
  background-color: #D9D9D9;
}
.imge_bg{
  background-size: cover;
  background-position: center;
}
.imge_bg_start{
  background-size: cover;
  background-position: top;
}
.my_rounded{
  border-radius: 8px;
}
.pointer{
  cursor: pointer;
}
.circle_rounded{
  border-radius: 50%;
}
.my_border{
  border: solid 2px black;
  border-radius: 8px;
}
.thin_border{
  border: solid 1px black;
  border-radius: 8px;
}
.display_none{
  display: none;
}
._my_greay_border{
  border: solid 1px gray;
  border-radius: 8px;
}
.dashed_border{
  border: dashed 2px #A4A4A4;
}
.border_buttom{
  border-bottom: solid 2px black;
  padding-bottom: 10px;
}
.border_buttom_greay{
  border-bottom: solid 1px gray;
  padding-bottom: 10px;
}
.flex_center{
  display: flex;
  align-items: center;
  justify-content: center;
}
.page_container{
  /* padding-right: 288px; */
  position: relative;
  margin-right: 288px;
}
.modal_container{
  padding-right: 250px;
  position: fixed;
  top: 0px;
  left: 0px;
  /* margin-right: 288px; */
  z-index: 10;
  width: 100%;
  height: 100vh;
  margin-right: 288px;
}
.modal_bg{
  background-color: #1E1E1E;
  opacity: 0.5;
  position: absolute;
  top: 0px;
  left: 0px;
  margin-right: 288px;
  height: 100vh;
  width: 100%;
  z-index: 9;
}
.z_index_over{
  z-index: 10;
}
.z_index_100{
  z-index: 90;
}
.z_index_1000{
  z-index: 1000;
}
.loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid black;
  width: 20px;
  height: 20px;
  margin: auto;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
.page_loader{
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid var(--secondary);
  width: 50px;
  height: 50px;
  margin: auto;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
.position_center{
  position: absolute;
  left: 61%;
  top: 200px;
  transform: translate(-50%,-50%);
}
/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* side bar */
.side_bar a:hover svg path{
  fill: var(--secondary);
}
/* year detailes modal */
.modal_content{
  background-color: white;
  z-index: 10;
  position: relative;
  border-radius: 8px;
  width: 90%;
  margin:0px auto ;
  padding: 25px 70px;
  overflow-y: scroll;
  height: 80vh;
}
.esc_modal_year_detailes{
  width: 90%;
  height: 40px;
  margin:50px auto -2px;
  background-color: rgb(73, 69, 69);
  color: white;
  z-index: 10;
  position: relative;
}
.esc_modal_year_detailes button{
  background-color: #1E1E1E;
  color: white;
  height: 100%;
  text-align: start;
  padding-right: 20px;
  width: 200px;
  font-size: 25px;
  z-index: 12;
  position: relative;
}
.year_image{
  height: 550px;
}
/* add year */
.input_file{
  display: none;
}
/* =====================data base page */
.database_page .filters{
  border-bottom: solid 1px gray;
  display: flex;
  width: fit-content;
}
.database_page .filters li{
  padding: 0px 16px 10px;
  font-size: 30px;
}
.database_page .filters li:hover{
  border-bottom: solid 2px black;
  cursor: pointer;
}
.database_page .filters .active_filter{
  border-bottom: solid 2px black;
}
 table{
  width: 100%;
  border: solid 1px gray;
  border-radius: 8px;
  margin-top: 40px;
}
 table td{
  padding: 9px;
}
 table thead{
  background-color: #EAEAEA;
  border-radius: 8px;
  padding: 10px 0px;
}
 table tbody tr{
  margin-top: 8px;
}
.small_modal_conrent{
  width: 650px;
  border-radius: 8px;
  background-color: white;
  position: relative;
  margin: 50px auto;
  z-index: 10;
  padding: 10px 10px 150px 10px;
  overflow-y: scroll;
  height: 620px;
}
.small_modal_conrent .titles{
  border-left: solid 2px gray ;
  padding-left: 5px;
  width: 120px;
}
.small_modal_conrent .titles h4{
  color: gray;
}
.person_data .row{
  display: flex;
}
.person_data .row h4{
  padding: 5px 0px;
}
.person_data .row .title{
  width: 150px;
  border-left: solid 2px gray ;
  padding-left: 8px;
  text-align: end;
}
.person_data .row .info{
  margin-right: 8px;
  color: gray;
  width: 400px;
}
.years_cell{
  cursor: pointer;
  position: relative;
}
.years_cell .first_year{
  position: absolute;
  top: 50%;
  left: 70%;
  transform: translate(-50%, -50%);
}
.years_cell .all_years{
  display: none;
}
.years_cell:hover .all_years{
  display: block;
  color: var(--secondary);
  position: absolute;
  top: 50%;
  left: 70%;
  transform: translate(-50%, -50%);
  background-color: #1E1E1E;
  padding: 4px;
  border-radius: 8px;
}
.years_cell:hover .first_year{
  display: none;
}
/*================================================== books page */
.books_page_container{
  padding: 20px 25px;
}
/*library slider */
.library_slider{
  position: relative;
  margin-top: 20px;
}
.library_slider .container{
  position: relative;
  display: flex;
  justify-content: space-between;
  overflow-x: scroll;
  -ms-overflow-style: none; 
  display: flex;
  transition: transform 0.3s ease-in-out;
  background-size: contain;
}
.library_slider .container::-webkit-scrollbar {
  display: none;
}
.library_slider .slider_elem{
  flex: 0 0 100%;
  background-size: cover;  
  background-repeat: no-repeat;
  position: relative;
  height: 350px;
  width: 100%;
  border-radius: 8px;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: 80px;
  align-items: center;
}
.library_slider .slider_elem .book_imge_container{
  /* position: relative; */
}
.library_slider .slider_elem .book_imge_container h4{
  background-color: var(--secondary);
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  border-radius: 50%;
  position: relative;
  top: 144px;
  right: -35px;
  font-family: "Amiri";
  color: #1E1E1E;
}
.library_slider .slider_elem img{
  height: 250px;
  display: block;
  width: auto; 
  max-width: 300px;  
}
.library_slider .container .content{
  padding: 200px 60px 30px 0px;
  color: white;
  width: fit-content;
}
 .library_slider .container .content{
  color: black !important;
}
 .library_slider .container .content h2 ,.library_slider .container .content h3{
  color: #1E1E1E;
  font-family: "Amiri";
}
.library_slider .youtube_icon{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.library_slider .btns{
  position: absolute;
  left: 50%;
  bottom: 0%;
  transform: translate(-50%,-50%);
}
.library_slider .arr_right,.library_slider .arr_left{
  width: 40px;
  height: 50px;
  position: absolute;
  transform: translate(0,-50%);
  background-color: var(--secondary);
  z-index: 90;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 5;
}
.library_slider .arr_right{
  right: 0%;
  top: 50%;
}
.library_slider .arr_left{
  left: 0%;
  top: 50%;
}
.categories_modal_content{
  height: 500px;
}
/* albums */
.album_imge{
  width: 500px;
  height: 500px;
}
.width_500{
  width: 500px;
}
/* ===============arcive page  */
.arcive_Page .filters{
  border-bottom: solid 1px gray;
  display: flex;
  width: fit-content;
  width: 100%;
}
.arcive_Page .filters li{
  padding: 0px 16px 10px;
  font-size: 25px;
}
.arcive_Page .filters li:hover{
  border-bottom: solid 2px black;
  cursor: pointer;
}
.arcive_Page .filters .active_filter{
  border-bottom: solid 2px black;
}
/*====================== requests page */
.requests_page{
  padding: 25px 90px;
}
.requests_page .filters{
  border-bottom: solid 1px gray;
  display: flex;
  width: fit-content;
  width: 100%;
}
.requests_page .filters li{
  padding: 0px 16px 10px;
  font-size: 20px;
  color: gray;
}
.requests_page .filters li:hover{
  border-bottom: solid 2px black;
  cursor: pointer;
  color: black !important;
}
.requests_page .filters .active_filter{
  border-bottom: solid 2px black;
  color: black !important;
}
.requests_page td{
  text-align: center;
}
.requests_page .content_type{
  padding: 5px 8px;
  border-radius: 18px;
  background: #d1e3fb;
}
.login__form {
  outline: solid 2px var(--secondary);
  outline-offset: 7px;
}
/* add person */
.tox-editor-container{
  border: solid 1px !important;
  border-radius: 9px !important;
}
/* requests */
.edit_new_content{
  z-index: 1000;
  margin: 72px 300px;
}
.rq_image{
  cursor: pointer;
  z-index: 10000 !important;
}
.rq_image_container{
  width: 200px !important;
  height: 60px !important;
  border-radius: 8px;
  padding: 5px;
  border: dashed 2px gray;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.show_Image{
  margin: 0px 4px;
}
.rq_image_container:hover .rq_image{
  position: fixed;
  width: 400px !important;
  height: 400px !important;
  bottom: 0px;
  z-index: 10000;
  /* left: 0px; */
}
.rq_image_container:hover h6{
  color: var(--secondary);
}

.highlight{
  background-color: red;
  cursor: pointer;
}
/* show image modal */
.test{
  z-index: 10000;
  /* position: absolute; */
  /* top:50% ; */
  /* left: 50%; */
}